<template>
  <div id="auth-page" class="background">
    <div class="d-flex justify-center align-center" style="width: 100%;">
      <!-- Login page -->
      <Login v-if="!authPage || authPage === 'login'" />
      <!-- Register page -->
      <Register v-if="authPage === 'register' && sk === '65dd4e5ca341700008afc551'" />
      <!-- Forgot password page -->
      <Forgot v-if="authPage === 'forgot'" />
      <Reset @setIsReset="setReset" v-if="authPage === 'reset-pwd-action'" />
      <!-- //shop && token -->
      <Shop v-if="authPage === 'shop'" />
      <CreateStaff v-if="authPage === 'accept'" />
      <VerifyEmail v-if="authPage === 'verify'" />
      <Reseted v-if="authPage === 'reseted'" />
    </div>
  </div>
</template>
<script>
import Login from './components/Login';
import Register from './components/Register';
import Forgot from './components/Forgot';
import Shop from './components/Shop';
import Reseted from './components/ResetedPw';
import Reset from './components/ResetPassword';
import STORAGE_NAME from '@/const/storage';
import { userService } from '@/apis/user.s';
import { mapMutations } from 'vuex';
import { CreateStaff, VerifyEmail } from './components/email-account';
export default {
  data() {
    return {
      loading: false,
      sk: null,
    };
  },
  props: ['authPage'],
  components: {
    Reseted,
    Reset,
    Login,
    Register,
    Forgot,
    Shop,
    CreateStaff,
    VerifyEmail,
  },
  async mounted() {
    let TOKEN = localStorage.getItem(STORAGE_NAME.ACCESS_TOKEN);
    let REFRESH_TOKEN = localStorage.getItem(STORAGE_NAME.REFRESH_TOKEN);
    let storeId = localStorage.getItem(STORAGE_NAME.STORE_ID);
    if (TOKEN && this.$route.params.authPage !== 'accept' && this.$route.params.authPage !== 'verify') {
      this.$router.push({ name: 'auth', params: { authPage: 'shop' } });
    }
  },
  beforeCreate() {
    if (this.$route.query.l) {
      localStorage.clear();
      this.$router.replace({ query: null });
    }
  },

  methods: {
    setReset(a) {
      this.loading = true;
      setTimeout(() => (this.loading = false), 2000);
    },
  },
  created() {
    if (this.$route.query?.code) {
      this.$store.commit('setCodeRefCreateStore', this.$route.query.code);
    }
    if (this.$store.state.auth.codeRefCreateStore) {
      this.$router.push({ query: { ...this.$route.query, code: this.$store.state.auth.codeRefCreateStore } });
    }
    if (this.$route.query?.sk) {
      this.sk = this.$route.query.sk;
    }
  },
  watch: {
    $route() {
      if (this.$store.state.auth.codeRefCreateStore) {
        this.$router.push({ query: { ...this.$route.query, code: this.$store.state.auth.codeRefCreateStore } });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
#auth-page {
  height: 100dvh;
  width: 100dvw;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
