<template>
  <div class="d-flex flex-column align-center justify-center" style="gap: 24px; width: 100%;">
    <Logo />
    <div class="shop-container">
      <div class="d-flex align-center justify-space-between mb-3">
        <h2>Select a shop</h2>
        <v-btn class="px-2" color="error" text @click="logout">
          <i class="ri-shut-down-line ri-lg mr-1"></i>
          Sign out
        </v-btn>
      </div>
      <!-- User detail -->
      <v-card class="d-flex align-center pa-4 mb-4" style="gap: 12px">
        <div>
          <v-avatar color="primary">
            <img src="/admin/img/avatar.jpg" :alt="fullName" />
          </v-avatar>
        </div>
        <div class="flex">
          <h3>{{ fullName }}</h3>
          <p v-if="user && user.email" class="mb-0">{{ user.email }}</p>
        </div>
      </v-card>
      <!-- Add a new shop -->
      <v-btn v-if="!showInput" color="primary" @click="showInput = !showInput" block depressed>
        <v-icon left>mdi-plus</v-icon>Create New Store</v-btn
      >
      <v-card v-else class="d-flex align-start pa-3" style="gap: 8px" outlined>
        <v-text-field
          v-model="store.name"
          :error-messages="
            invalid && !$v.store.name.required
              ? $t('Store name is required')
              : invalid && !$v.store.name.minLength
              ? $t('Store name must have at least ' + $v.store.name.$params.minLength.min + ' letters.')
              : invalid && !$v.store.name.maxLength
              ? $t('Store name must have at least ' + $v.store.name.$params.maxLength.max + ' letters.')
              : invalid && !$v.store.name.alpha
              ? $t('Store name cannot contain special characters')
              : error
              ? $t(error)
              : ''
          "
          placeholder="Enter store name"
          hide-details="auto"
        ></v-text-field>
        <v-btn :loading="isLoadingButton" class="mt-1" color="primary" depressed @click="addStore">Save</v-btn>
      </v-card>
      <div v-if="isLoading" class="mt-4">
        <v-skeleton-loader type="list-item-avatar-three-line"></v-skeleton-loader>
        <v-skeleton-loader type="list-item-avatar-three-line"></v-skeleton-loader>
        <v-skeleton-loader type="list-item-avatar-three-line"></v-skeleton-loader>
        <v-skeleton-loader type="list-item-avatar-three-line"></v-skeleton-loader>
        <v-skeleton-loader type="list-item-avatar-three-line"></v-skeleton-loader>
        <v-skeleton-loader type="list-item-avatar-three-line"></v-skeleton-loader>
      </div>
      <v-card v-else class="mt-4 no-scrollbar" style="max-height: 50dvh; overflow-y: auto">
        <v-list class="py-0">
          <v-list-item v-for="(store, index) in stores" :key="index" class="pr-2" @click="onClickShop(store)">
            <div class="border pa-2 rounded mr-4">
              <i class="ri-store-2-line ri-xl info--text"></i>
            </div>
            <v-list-item-content>
              <v-list-item-title class="font-weight-medium">{{ store.storeDetail.name }}</v-list-item-title>
              <v-list-item-subtitle>{{ store.storeDetail.defaultSystemDomain }} </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn icon disabled>
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card>
    </div>
  </div>
</template>

<script>
import { authService } from '@/apis/auth.s';
import { userService } from '@/apis/user.s';
import { storeService } from '@/apis/store.s';
import STORAGE_NAME from '@/const/storage';
import { mapGetters, mapMutations } from 'vuex';
import { required, minLength, maxLength, helpers } from 'vuelidate/lib/validators';
const alpha = helpers.regex('alpha', /^[a-zA-Z0-9]*$/);
import verifyMixin from '@/mixins/verify';
import { widgetApi } from '@/apis/widget';
import Logo from './Logo';
export default {
  components: {
    Logo,
  },
  mixins: [verifyMixin],
  validations: {
    store: {
      name: {
        required,
        alpha,
        minLength: minLength(4),
        maxLength: maxLength(50),
      },
    },
  },
  data() {
    let defaultConfig = {
      activated: false,
      device: 'desktop',
      widgetType: 'bestSeller',
      widgetCustomize: {
        productPrice: {
          align: 'center',
          bold: false,
          fontColor: '#000000',
          fontSize: '14px',
          italic: false,
          underline: false,
          textTitle: 'Title ',
        },
        productName: {
          align: 'center',
          bold: false,
          fontColor: '#000000',
          fontSize: '14px',
          italic: false,
          underline: false,
          textTitle: 'Programmer Label Personalized',
        },
        place: {
          cartPage: false,
          categoryPage: false,
          homePage: false,
          productPage: true,
          page: {
            collectionIds: [],
            productIds: [],
            selectType: 'products',
            isShowAtSpecificPage: false,
          },
        },
        header: {
          align: 'center',
          bold: false,
          fontColor: '#000000',
          fontSize: '25px',
          italic: false,
          underline: false,
          textTitle: 'Handpicked products just for you',
        },

        addCartButton: {
          enabled: true,
          callToAction: {
            align: 'center',
            bold: false,
            fontColor: '#000000',
            fontSize: '12px',
            italic: false,
            underline: false,
            textTitle: 'Title ',
          },
        },
        layout: '1',
        numberOfProducts: {
          productsPerSlide: 6,
          productsToBeShown: 12,
        },
      },
    };
    return {
      defaultConfig,
      type: 'password',
      isLoading: true,
      isLoadingButton: false,
      stores: [],
      showMenu: false,
      x: 0,
      y: 0,
      invalid: false,
      showInput: false,
      store: {
        name: '',
      },
      error: null,
    };
  },
  created() {
    this.auth();
    this.getUserInfo();
    this.getStores();
  },
  methods: {
    ...mapMutations(['setUser']),
    async createAllAfterCreateStore(storeId, defaultSystemDomain) {
      let [cartRecommend, moreCollection, bestSeller, recentView, alsoBought, handpickedProduct, pickForYou] = [
        {
          ...this.defaultConfig,
          widgetType: 'cartRecommend',
          activated: false,
          widgetCustomize: {
            ...this.defaultConfig.widgetCustomize,
            header: {
              ...this.defaultConfig.widgetCustomize.header,
              textTitle: 'Cart recommendations',
            },
            place: {
              cartPage: true,
              homePage: true,
              productPage: true,
              page: {
                collectionIds: [],
                productIds: [],
                selectType: 'products',
                isShowAtSpecificPage: false,
              },
            },
          },
        },
        {
          ...this.defaultConfig,
          widgetType: 'moreCollection',
          activated: false,
          widgetCustomize: {
            ...this.defaultConfig.widgetCustomize,
            header: {
              ...this.defaultConfig.widgetCustomize.header,
              textTitle: 'More from {{collection_name}}',
            },
            place: {
              cartPage: true,
              homePage: false,
              productPage: true,
              page: {
                collectionIds: [],
                productIds: [],
                selectType: 'products',
                isShowAtSpecificPage: false,
              },
            },
          },
        },
        {
          ...this.defaultConfig,
          widgetType: 'bestSeller',
          activated: false,
          widgetCustomize: {
            ...this.defaultConfig.widgetCustomize,
            header: {
              ...this.defaultConfig.widgetCustomize.header,
              textTitle: 'Store best sellers',
            },
            place: {
              homePage: true,
              cartPage: false,
              productPage: false,
              page: {
                collectionIds: [],
                productIds: [],
                selectType: 'products',
                isShowAtSpecificPage: false,
              },
            },
          },
        },
        {
          ...this.defaultConfig,
          widgetType: 'recentView',
          activated: false,
          widgetCustomize: {
            ...this.defaultConfig.widgetCustomize,
            header: {
              ...this.defaultConfig.widgetCustomize.header,
              textTitle: 'Recently viewed & featured recommendations',
            },
            place: {
              cartPage: true,
              homePage: true,
              productPage: true,
              page: {
                collectionIds: [],
                productIds: [],
                selectType: 'products',
                isShowAtSpecificPage: false,
              },
            },
          },
        },
        {
          ...this.defaultConfig,
          widgetType: 'alsoBought',
          activated: false,
          widgetCustomize: {
            ...this.defaultConfig.widgetCustomize,
            header: {
              ...this.defaultConfig.widgetCustomize.header,
              textTitle: 'Who bought this also bought',
            },
          },
        },
        {
          ...this.defaultConfig,
          widgetType: 'handpickedProduct',
          activated: false,
          widgetCustomize: {
            ...this.defaultConfig.widgetCustomize,
            place: {
              productPage: true,
              page: {
                collectionIds: [],
                productIds: [],
                selectType: 'products',
                isShowAtSpecificPage: false,
              },
            },
          },
        },
        {
          ...this.defaultConfig,
          widgetType: 'pickForYou',
          activated: false,
          widgetCustomize: {
            ...this.defaultConfig.widgetCustomize,
            header: {
              ...this.defaultConfig.widgetCustomize.header,
              textTitle: 'Pick For You',
            },
            showWith: 'sameTitle',
            place: {
              productPage: true,
              page: {
                collectionIds: [],
                productIds: [],
                selectType: 'products',
                isShowAtSpecificPage: false,
              },
            },
          },
        },
      ];
      //cartRecommend

      let res = await widgetApi.createAllAfterCreateStore(
        {
          productWidgets: [
            cartRecommend,
            moreCollection,
            bestSeller,
            recentView,
            alsoBought,
            handpickedProduct,
            pickForYou,
          ],
        },
        storeId,
        defaultSystemDomain,
      );
    },
    async getUserInfo() {
      try {
        this.isLoading = true;
        let data = await userService.get();
        if (data) {
          this.setUser(data.data);
          localStorage.removeItem('dcomcy-user');
          localStorage.setItem('dcomcy-user', JSON.stringify(data.data));
        }
      } catch (error) {
        console.log(error);
      }
    },
    /*
    Func show dropdown
    */
    show(e) {
      e.preventDefault();
      this.showMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        this.showMenu = true;
      });
    },
    /*
    Fnc click and redirect to admin
    */
    async onClickShop(store) {
      window.localStorage.setItem(STORAGE_NAME.STORE_ID, store.id);
      window.localStorage.setItem(STORAGE_NAME.STORE_DOMAIN, store.storeDetail.defaultSystemDomain);
      let access_token = window.localStorage.getItem(STORAGE_NAME.ACCESS_TOKEN);
      let refresh_token = window.localStorage.getItem(STORAGE_NAME.REFRESH_TOKEN);
      // localStorage.clear();
      try {
        let data = await authService.checkSurvey({
          storeId: store.id,
          token: access_token,
        });
        console.log('🚀 ~ file: Shop.vue:406 ~ onClickShop ~ data:', data);
        if (data.status == 200) {
          setTimeout(() => {
            let baseUrl = store.storeDetail.primaryDomain || store.storeDetail.defaultSystemDomain;
            let url = 'https://' + baseUrl + '/admin/';
            if (window.location.href.includes('localhost')) {
              // For deverlopment
              url = `${window.location.origin}/admin/`;
            }

            window.location.href =
              url +
              '?access_token=' +
              access_token +
              '&refresh_token=' +
              refresh_token +
              '&system_domain=' +
              store.storeDetail.defaultSystemDomain +
              '&store_id=' +
              store.id +
              '&r_domain=' +
              (store.primaryDomain ? store.primaryDomain : store.storeDetail.defaultSystemDomain);
          }, 0);
        }
      } catch (error) {
        localStorage.setItem('defaultSystemDomain', store.storeDetail.defaultSystemDomain);
        this.$router.push({
          name: 'auth/survey',
          query: {
            register: store.storeDetail.name,
            storeId: store.id,
            defaultSystemDomain: store.storeDetail.defaultSystemDomain,
          },
        });
        console.log('🚀 ~ file: Shop.vue:408 ~ onClickShop ~ error:', error);
      }
    },
    /*
    Fnc get user stores
    */
    async getStores() {
      try {
        const { data } = await userService.getStores();
        this.stores = data?.filter(store => store !== null)?.reverse() || [];
      } catch (error) {
        console.log('🚀 ~ getStores ~ error:', error);
      } finally {
        this.isLoading = false;
      }
    },
    /*
    Fnc logout
    */
    logout() {
      try {
        authService.logout();
        localStorage.clear();
        this.$router.push({ name: 'home' });
      } catch (error) {
        console.log(error.response);
      }
    },
    /*
    Fnc add store
    */
    async addStore() {
      this.$v.$touch();
      this.invalid = this.$v.$invalid;
      this.isLoadingButton = true;
      if (!this.$v.$invalid) {
        try {
          const codeRef = this.$store.state?.auth.codeRefCreateStore;
          let newStore = await userService.addStore(codeRef ? { ...this.store, code: codeRef } : this.store);
          await this.getStores();
          let storeId = newStore ? newStore.data.storeId : '';
          let findStore = this.stores.find(s => s.id === storeId);
          let defaultSystemDomain = findStore ? findStore.storeDetail.defaultSystemDomain : '';
          localStorage.setItem(STORAGE_NAME.STORE_DOMAIN, defaultSystemDomain);
          await this.addPayments(storeId);
          localStorage.setItem('store-id', storeId);
          localStorage.setItem('defaultSystemDomain', defaultSystemDomain);
          this.createAllAfterCreateStore(storeId, defaultSystemDomain);
          this.$store.commit('setMessages', {
            messages: 'Create store successfully!',
            type: 'success',
          });
          this.$router.push({
            name: 'auth/survey',
            query: { register: this.store.name, storeId: storeId, defaultSystemDomain: defaultSystemDomain },
          });
          this.store.name = '';
          // this.createAllWidget();
          this.$v.$reset();
          this.invalid = false;
          this.error = null;
          this.isLoadingButton = false;
        } catch (error) {
          this.error = error;
          this.isLoadingButton = false;
        }
      } else {
        this.isLoadingButton = false;
      }
    },

    async addPayments(storeId) {
      try {
        let payments = await storeService.addPayments(storeId);
        console.log('payments', payments);
      } catch (error) {
        console.log(error.response);
      }
    },
  },
  computed: {
    ...mapGetters(['user']),
    fullName() {
      if (!this.user?.firstName && !this.user?.lastName) return 'User';
      return (this.user.firstName ? this.user.firstName : '') + ' ' + (this.user.lastName ? this.user.lastName : '');
    },
  },
};
</script>
<style lang="scss" scoped>
.shop-container {
  width: 100%;
  max-width: 400px;
}
</style>
