<template>
  <div class="d-flex flex-column align-center justify-center" style="gap: 24px">
    <!-- <Back /> -->
    <Logo />
    <v-card class="register-form pa-7">
      <div class="text-center">
        <h1 class="my-0">Sign up</h1>
        <p class="mb-0">
          Already have an account?
          <router-link class="router-link font-weight-bold" :to="{ path: '/auth/login', query: { ...$route.query } }"
            >Sign in</router-link
          >
        </p>
      </div>
      <Notification v-if="errorMessage.length > 0" :messages="errorMessage" type="error" class="mt-4" />
      <v-row class="mt-7" dense>
        <v-col cols="6">
          <label> Email </label>
          <v-text-field
            autocomplete="off"
            name="email"
            id="email"
            v-model.trim="$v.email.$model"
            :error-messages="
              invalid && !$v.email.required
                ? $t('Field is required')
                : invalid && !$v.email.email
                ? $t('Invalid Email')
                : invalid && !$v.email.minLength
                ? $t('Email must have at least ' + $v.email.$params.minLength.min + ' letters.')
                : invalid && !$v.email.maxLength
                ? $t('Email must have at least ' + $v.email.$params.maxLength.max + ' letters.')
                : ''
            "
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <label> Password </label>
          <v-text-field
            :type="type"
            autocomplete="off"
            name="password"
            id="password"
            v-model.trim="$v.password.$model"
            :error-messages="
              invalid && !$v.password.required
                ? $t('Password is required')
                : invalid && !$v.password.minLength
                ? $t('Password must have at least ' + $v.password.$params.minLength.min + ' letters.')
                : invalid && !$v.password.maxLength
                ? $t('Password must have at least ' + $v.password.$params.maxLength.max + ' letters.')
                : ''
            "
          >
            <template slot="append">
              <v-btn @click="type = type === 'text' ? 'password' : 'text'" icon small
                ><v-icon v-if="type == 'text'" :size="20">mdi-eye</v-icon>
                <v-icon v-else :size="20">mdi-eye-off-outline</v-icon></v-btn
              >
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="12">
          <label> Store Name </label>
          <v-text-field
            v-on:keyup.enter="onSignup"
            autocomplete="off"
            name="shop_name"
            id="shop_name"
            v-model.trim="$v.shopName.$model"
            :error-messages="
              invalid && !$v.shopName.required
                ? $t('Shop name is required')
                : invalid && !$v.shopName.minLength
                ? $t('Shop name must have at least ' + $v.shopName.$params.minLength.min + ' letters.')
                : invalid && !$v.shopName.maxLength
                ? $t('Shop name must have at least ' + $v.shopName.$params.maxLength.max + ' letters.')
                : invalid && !$v.shopName.alpha
                ? $t('Shop name cannot contain special characters')
                : ''
            "
          ></v-text-field>
        </v-col>
      </v-row>

      <v-btn @click="onSignup" :loading="isLoading" block depressed color="primary">
        Sign Up
      </v-btn>
    </v-card>
  </div>
</template>

<script>
import { required, minLength, maxLength, email, helpers } from 'vuelidate/lib/validators';
const alpha = helpers.regex('alpha', /^[a-zA-Z0-9]*$/);
import { authService } from '@/apis/auth.s';
import STORAGE_NAME from '@/const/storage';
import Notification from '@/components/Notification';
import { mapMutations } from 'vuex';
import accountRequest from '@/apis/request/accountRequest';
// import Back from './Back.vue';
import { storeService } from '@/apis/store.s';
import { userService } from '@/apis/user.s';
import Logo from './Logo.vue';

export default {
  components: {
    Notification,
    // Back,
    Logo,
  },
  data() {
    return {
      type: 'password',
      isLoading: false,
      email: '',
      password: '',
      shopName: '',
      invalid: false,
      errorMessage: [],
      stores: [],
    };
  },
  validations: {
    email: {
      required,
      email,
      minLength: minLength(4),
      maxLength: maxLength(50),
    },
    password: {
      required,
      minLength: minLength(6),
      maxLength: maxLength(50),
    },
    shopName: {
      required,
      alpha,
      minLength: minLength(4),
      maxLength: maxLength(50),
    },
  },
  methods: {
    /*
    Fnc Signup
    */
    async onSignup() {
      this.isLoading = true;
      this.$v.$touch();
      this.invalid = this.$v.$invalid;
      this.errorMessage = [];
      if (!this.$v.$invalid) {
        try {
          const codeRef = this.$store.state.auth.codeRefCreateStore;
          let data = await authService.register(this.email, this.password, this.shopName, codeRef);
          window.localStorage.setItem(STORAGE_NAME.ACCESS_TOKEN, data.data.accessToken || '');
          window.localStorage.setItem(STORAGE_NAME.REFRESH_TOKEN, data.data.refreshToken || '');
          window.localStorage.setItem(STORAGE_NAME.STORE_ID, data.data.storeId || '');
          this.isLoading = false;
          accountRequest.setToken(data.data.accessToken);

          await this.getStores();
          let storeId = data.data.storeId;
          let findStore = this.stores.find(s => s.id === storeId);
          let defaultSystemDomain = findStore ? findStore.storeDetail.defaultSystemDomain : '';
          localStorage.setItem(STORAGE_NAME.STORE_DOMAIN, defaultSystemDomain);
          await this.addPayments(storeId);

          setTimeout(() => {
            this.$router.push({ name: 'auth/survey', query: { register: '' } });
          }, 1000);
        } catch (error) {
          this.errorMessage = error;
          this.isLoading = false;
        }
      } else {
        this.isLoading = false;
      }
    },

    async addPayments(storeId) {
      try {
        let payments = await storeService.addPayments(storeId);
        console.log('payments', payments);
      } catch (error) {
        console.log(error.response);
      }
    },

    async getStores() {
      try {
        let data = await userService.getStores();
        this.stores = data.data.reverse().filter(store => store !== null);
      } catch (error) {
        console.log(error.response);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.register-form {
  width: 100%;
  max-width: 480px;
}
</style>
