<template>
  <v-col cols="12" sm="9">
    <h1 class="">Email verification</h1>
    <v-alert v-if="isDone" dense color="primary" style="color:white">
      You've successfully verified your email.
    </v-alert>
    <v-btn
      elevation=""
      color="primary"
      @click="
        $router.push({
          name: 'auth',
          params: {
            authPage: 'login',
          },
        })
      "
    >
      Goto dashboard</v-btn
    >
  </v-col>
</template>

<script>
import { authService } from '@/apis/auth.s';

export default {
  data() {
    return {
      isDone: false,
    };
  },
  methods: {
    async checkData() {
      try {
        let response = await authService.accountActivation(this.$route.query.access_token);
        this.isDone = true;
      } catch (error) {
        this.isDone = false;
      }
    },
  },
  async created() {
    // router  auth/accept?t=

    if (this.$route.query && this.$route.query.access_token) {
      await this.checkData();
      //empty
    }
  },
};
</script>
