<template>
  <div class="d-flex flex-column align-center justify-center" style="gap: 24px">
    <Logo />
    <v-card class="reset-form pa-4">
      <h2 class="mb-4 text-center">Reset Your Password</h2>
      <label>New password</label>
      <v-text-field
        type="password"
        v-model.trim="$v.newPassword.$model"
        :error-messages="
          invalid && !$v.newPassword.required
            ? $t('Password is required')
            : invalid && !$v.newPassword.minLength
            ? $t('Password must have at least ' + $v.newPassword.$params.minLength.min + ' characters')
            : ''
        "
      ></v-text-field>
      <label>Confirm password</label>
      <v-text-field
        type="password"
        v-model.trim="confirmPassword"
        hide-details="auto"
        :error-messages="
          invalid && !$v.confirmPassword.sameAsPassword
            ? $t('Your password and confirmation password do not match')
            : ''
        "
      ></v-text-field>
      <div class="d-flex justify-end mt-6" style="gap: 8px">
        <v-btn text outlined @click="cancel" depressed>Cancel</v-btn>
        <v-btn color="primary" @click="reset" depressed>Reset password</v-btn>
      </div>
    </v-card>
  </div>
</template>

<script>
import { required, minLength, sameAs } from 'vuelidate/lib/validators';
import { authService } from '@/apis/auth.s';
import Logo from './Logo.vue';

export default {
  components: {
    Logo,
  },
  data() {
    return {
      newPassword: '',
      confirmPassword: '',
      invalid: false,
      tokenn: '',
      clicked: false,
    };
  },
  validations: {
    newPassword: {
      required,
      minLength: minLength(5),
    },
    confirmPassword: {
      sameAsPassword: sameAs('newPassword'),
    },
  },
  created() {
    this.getTokenn();
  },
  methods: {
    async getTokenn() {
      try {
        this.tokenn = this.$route.query.token;
        console.log(this.tokenn);
        // để check xem đã reset pass lần nào chưa, nếu ở try thì chưa
        await authService.getToken(this.$route.query.token);
      } catch (error) {
        // còn đã reset rồi thì api trả về 406
        this.$router.push({ path: '/auth/reseted' });
      }
    },
    cancel() {
      this.$router.push({ path: '/auth/login' });
    },
    async reset() {
      this.$v.$touch();
      this.invalid = this.$v.$invalid;
      if (!this.invalid && this.confirmPassword == this.newPassword) {
        // dosomething
        let params = {};
        params.password = this.newPassword;
        params.token = this.tokenn;
        await authService.resetPw(params);
        this.$router.push({ path: '/auth/login' });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.reset-form {
  width: 100%;
  max-width: 400px;
}
</style>
