var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column align-center justify-center",staticStyle:{"gap":"24px"}},[_c('Logo'),_c('v-card',{staticClass:"forgot-form pa-7"},[(_vm.isSendEmail)?_c('div',{staticClass:"text-center"},[_c('div',{staticClass:"success pa-5 mx-auto mb-4 lighten-5 rounded-circle",staticStyle:{"width":"fit-content"}},[_c('v-icon',{attrs:{"color":"success","size":48}},[_vm._v("mdi-email-check-outline")])],1),_c('p',{staticClass:"mb-0"},[_vm._v(" Instructions to reset your password have beeen emailed to you. Not receive email yet? "),_c('strong',{staticClass:"pointer primary--text",on:{"click":_vm.onForgot}},[_vm._v("Resend")])])]):_c('div',[_c('h2',{staticClass:"mb-2 text-center"},[_vm._v("Forgot your password?")]),_c('p',{staticClass:"text-center"},[_vm._v(" Remember your password? "),_c('router-link',{staticClass:"router-link font-weight-medium",attrs:{"to":{ path: '/auth/login', query: Object.assign({}, this.$route.query) }},on:{"click":_vm.onReturn}},[_vm._v("Sign in here")])],1),_c('label',[_vm._v(" Email address")]),_c('v-text-field',{attrs:{"placeholder":"example@gmail.com","autocomplete":"off","name":"email","id":"email","error-messages":_vm.invalid && !_vm.$v.email.required
            ? _vm.$t('Email is required')
            : _vm.invalid && !_vm.$v.email.email
            ? _vm.$t('Invalid Email')
            : _vm.invalid && !_vm.$v.email.minLength
            ? _vm.$t('Email must have at least ' + _vm.$v.email.$params.minLength.min + ' letters.')
            : _vm.invalid && !_vm.$v.email.maxLength
            ? _vm.$t('Email must have at least ' + _vm.$v.email.$params.maxLength.max + ' letters.')
            : ''},model:{value:(_vm.$v.email.$model),callback:function ($$v) {_vm.$set(_vm.$v.email, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.email.$model"}}),_c('v-btn',{attrs:{"loading":_vm.isLoading,"block":"","color":"primary","depressed":""},on:{"click":_vm.onForgot}},[_vm._v("Send Reset Link")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }