<template>
  <div class="d-flex flex-column align-center justify-center" style="gap: 24px">
    <Logo />
    <v-card class="forgot-form pa-7">
      <div v-if="isSendEmail" class="text-center">
        <div class="success pa-5 mx-auto mb-4 lighten-5 rounded-circle" style="width: fit-content;">
          <v-icon color="success" :size="48">mdi-email-check-outline</v-icon>
        </div>
        <p class="mb-0">
          Instructions to reset your password have beeen emailed to you. Not receive email yet?
          <strong @click="onForgot" class="pointer primary--text">Resend</strong>
        </p>
      </div>

      <div v-else>
        <h2 class="mb-2 text-center">Forgot your password?</h2>
        <p class="text-center">
          Remember your password?
          <router-link
            class="router-link font-weight-medium"
            :to="{ path: '/auth/login', query: { ...this.$route.query } }"
            @click="onReturn"
            >Sign in here</router-link
          >
        </p>
        <label> Email address</label>
        <v-text-field
          placeholder="example@gmail.com"
          autocomplete="off"
          name="email"
          id="email"
          v-model.trim="$v.email.$model"
          :error-messages="
            invalid && !$v.email.required
              ? $t('Email is required')
              : invalid && !$v.email.email
              ? $t('Invalid Email')
              : invalid && !$v.email.minLength
              ? $t('Email must have at least ' + $v.email.$params.minLength.min + ' letters.')
              : invalid && !$v.email.maxLength
              ? $t('Email must have at least ' + $v.email.$params.maxLength.max + ' letters.')
              : ''
          "
        ></v-text-field>
        <v-btn @click="onForgot" :loading="isLoading" block color="primary" depressed>Send Reset Link</v-btn>
      </div>
    </v-card>
  </div>
</template>

<script>
import { required, minLength, maxLength, email } from 'vuelidate/lib/validators';
import { authService } from '@/apis/auth.s';
// import Back from './Back.vue';
import Logo from './Logo.vue';

export default {
  data() {
    return {
      isLoading: false,
      email: '',
      invalid: false,
      isSendEmail: false,
    };
  },
  validations: {
    email: {
      required,
      email,
      minLength: minLength(4),
      maxLength: maxLength(50),
    },
  },
  methods: {
    onReturn() {
      this.isSendEmail = false;
    },
    async onForgot() {
      this.isLoading = true;
      this.$v.$touch();
      this.invalid = this.$v.$invalid;
      try {
        if (!this.$v.$invalid) {
          //empty
          await authService.sendForgotEmail(this.email.toLowerCase());
          console.log(this.$router);
          // let a = await authService.getToken();
          // console.log(a);
          this.isSendEmail = true;
        }
        setTimeout(() => {
          this.isLoading = false;
        }, 500);
      } catch (error) {
        this.isLoading = false;
      }
    },
  },
  components: { Logo },
};
</script>

<style lang="scss" scoped>
.forgot-form {
  width: 100%;
  max-width: 460px;
}
</style>
