var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column align-center justify-center",staticStyle:{"gap":"24px","width":"100%"}},[_c('Logo'),_c('div',{staticClass:"shop-container"},[_c('div',{staticClass:"d-flex align-center justify-space-between mb-3"},[_c('h2',[_vm._v("Select a shop")]),_c('v-btn',{staticClass:"px-2",attrs:{"color":"error","text":""},on:{"click":_vm.logout}},[_c('i',{staticClass:"ri-shut-down-line ri-lg mr-1"}),_vm._v(" Sign out ")])],1),_c('v-card',{staticClass:"d-flex align-center pa-4 mb-4",staticStyle:{"gap":"12px"}},[_c('div',[_c('v-avatar',{attrs:{"color":"primary"}},[_c('img',{attrs:{"src":"/admin/img/avatar.jpg","alt":_vm.fullName}})])],1),_c('div',{staticClass:"flex"},[_c('h3',[_vm._v(_vm._s(_vm.fullName))]),(_vm.user && _vm.user.email)?_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.user.email))]):_vm._e()])]),(!_vm.showInput)?_c('v-btn',{attrs:{"color":"primary","block":"","depressed":""},on:{"click":function($event){_vm.showInput = !_vm.showInput}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v("Create New Store")],1):_c('v-card',{staticClass:"d-flex align-start pa-3",staticStyle:{"gap":"8px"},attrs:{"outlined":""}},[_c('v-text-field',{attrs:{"error-messages":_vm.invalid && !_vm.$v.store.name.required
            ? _vm.$t('Store name is required')
            : _vm.invalid && !_vm.$v.store.name.minLength
            ? _vm.$t('Store name must have at least ' + _vm.$v.store.name.$params.minLength.min + ' letters.')
            : _vm.invalid && !_vm.$v.store.name.maxLength
            ? _vm.$t('Store name must have at least ' + _vm.$v.store.name.$params.maxLength.max + ' letters.')
            : _vm.invalid && !_vm.$v.store.name.alpha
            ? _vm.$t('Store name cannot contain special characters')
            : _vm.error
            ? _vm.$t(_vm.error)
            : '',"placeholder":"Enter store name","hide-details":"auto"},model:{value:(_vm.store.name),callback:function ($$v) {_vm.$set(_vm.store, "name", $$v)},expression:"store.name"}}),_c('v-btn',{staticClass:"mt-1",attrs:{"loading":_vm.isLoadingButton,"color":"primary","depressed":""},on:{"click":_vm.addStore}},[_vm._v("Save")])],1),(_vm.isLoading)?_c('div',{staticClass:"mt-4"},[_c('v-skeleton-loader',{attrs:{"type":"list-item-avatar-three-line"}}),_c('v-skeleton-loader',{attrs:{"type":"list-item-avatar-three-line"}}),_c('v-skeleton-loader',{attrs:{"type":"list-item-avatar-three-line"}}),_c('v-skeleton-loader',{attrs:{"type":"list-item-avatar-three-line"}}),_c('v-skeleton-loader',{attrs:{"type":"list-item-avatar-three-line"}}),_c('v-skeleton-loader',{attrs:{"type":"list-item-avatar-three-line"}})],1):_c('v-card',{staticClass:"mt-4 no-scrollbar",staticStyle:{"max-height":"50dvh","overflow-y":"auto"}},[_c('v-list',{staticClass:"py-0"},_vm._l((_vm.stores),function(store,index){return _c('v-list-item',{key:index,staticClass:"pr-2",on:{"click":function($event){return _vm.onClickShop(store)}}},[_c('div',{staticClass:"border pa-2 rounded mr-4"},[_c('i',{staticClass:"ri-store-2-line ri-xl info--text"})]),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(store.storeDetail.name))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(store.storeDetail.defaultSystemDomain)+" ")])],1),_c('v-list-item-action',[_c('v-btn',{attrs:{"icon":"","disabled":""}},[_c('v-icon',[_vm._v("mdi-chevron-right")])],1)],1)],1)}),1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }