<template>
  <v-card class="reseted-form pa-7 text-center">
    <h2 class="mb-4">The page youʼre looking for canʼt be found</h2>
    <v-btn color="primary" depressed @click="back"><v-icon left :size="24">mdi-home</v-icon> Back to admin</v-btn>
  </v-card>
</template>

<script>
export default {
  methods: {
    back() {
      // this.isResetPw = true;
      this.$router.push({ name: 'auth', params: { authPage: 'login' } });
    },
  },
};
</script>

<style lang="scss" scoped>
.reseted-form {
  width: 100%;
  max-width: 400px;
}
</style>
