var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column align-center justify-center",staticStyle:{"gap":"24px"}},[_c('Logo'),_c('v-card',{staticClass:"register-form pa-7"},[_c('div',{staticClass:"text-center"},[_c('h1',{staticClass:"my-0"},[_vm._v("Sign up")]),_c('p',{staticClass:"mb-0"},[_vm._v(" Already have an account? "),_c('router-link',{staticClass:"router-link font-weight-bold",attrs:{"to":{ path: '/auth/login', query: Object.assign({}, _vm.$route.query) }}},[_vm._v("Sign in")])],1)]),(_vm.errorMessage.length > 0)?_c('Notification',{staticClass:"mt-4",attrs:{"messages":_vm.errorMessage,"type":"error"}}):_vm._e(),_c('v-row',{staticClass:"mt-7",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c('label',[_vm._v(" Email ")]),_c('v-text-field',{attrs:{"autocomplete":"off","name":"email","id":"email","error-messages":_vm.invalid && !_vm.$v.email.required
              ? _vm.$t('Field is required')
              : _vm.invalid && !_vm.$v.email.email
              ? _vm.$t('Invalid Email')
              : _vm.invalid && !_vm.$v.email.minLength
              ? _vm.$t('Email must have at least ' + _vm.$v.email.$params.minLength.min + ' letters.')
              : _vm.invalid && !_vm.$v.email.maxLength
              ? _vm.$t('Email must have at least ' + _vm.$v.email.$params.maxLength.max + ' letters.')
              : ''},model:{value:(_vm.$v.email.$model),callback:function ($$v) {_vm.$set(_vm.$v.email, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.email.$model"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('label',[_vm._v(" Password ")]),_c('v-text-field',{attrs:{"type":_vm.type,"autocomplete":"off","name":"password","id":"password","error-messages":_vm.invalid && !_vm.$v.password.required
              ? _vm.$t('Password is required')
              : _vm.invalid && !_vm.$v.password.minLength
              ? _vm.$t('Password must have at least ' + _vm.$v.password.$params.minLength.min + ' letters.')
              : _vm.invalid && !_vm.$v.password.maxLength
              ? _vm.$t('Password must have at least ' + _vm.$v.password.$params.maxLength.max + ' letters.')
              : ''},model:{value:(_vm.$v.password.$model),callback:function ($$v) {_vm.$set(_vm.$v.password, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.password.$model"}},[_c('template',{slot:"append"},[_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){_vm.type = _vm.type === 'text' ? 'password' : 'text'}}},[(_vm.type == 'text')?_c('v-icon',{attrs:{"size":20}},[_vm._v("mdi-eye")]):_c('v-icon',{attrs:{"size":20}},[_vm._v("mdi-eye-off-outline")])],1)],1)],2)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('label',[_vm._v(" Store Name ")]),_c('v-text-field',{attrs:{"autocomplete":"off","name":"shop_name","id":"shop_name","error-messages":_vm.invalid && !_vm.$v.shopName.required
              ? _vm.$t('Shop name is required')
              : _vm.invalid && !_vm.$v.shopName.minLength
              ? _vm.$t('Shop name must have at least ' + _vm.$v.shopName.$params.minLength.min + ' letters.')
              : _vm.invalid && !_vm.$v.shopName.maxLength
              ? _vm.$t('Shop name must have at least ' + _vm.$v.shopName.$params.maxLength.max + ' letters.')
              : _vm.invalid && !_vm.$v.shopName.alpha
              ? _vm.$t('Shop name cannot contain special characters')
              : ''},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onSignup($event)}},model:{value:(_vm.$v.shopName.$model),callback:function ($$v) {_vm.$set(_vm.$v.shopName, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.shopName.$model"}})],1)],1),_c('v-btn',{attrs:{"loading":_vm.isLoading,"block":"","depressed":"","color":"primary"},on:{"click":_vm.onSignup}},[_vm._v(" Sign Up ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }